// CLASSES: Helpers

// Add every helper classes you want.
// 👉 Example below: .spacer

// z-index
$z-indexes: (
  'cursor',
  'cookieconsent',
  'preloader',
  'transition',
  'modal',
  'navigation',
  'content',
  'background',
  'canvas',
);

.scroll--off {
    overflow: hidden;
}

.visually-hidden, 
.visually-hidden-focusable:not(:focus):not(:focus-within){
  position: absolute!important;
  width: 1px!important;
  height: 1px!important;
  padding: 0!important;
  margin: -1px!important;
  overflow: hidden!important;
  clip: rect(0,0,0,0)!important;
  white-space: nowrap!important;
  border: 0!important;
}
