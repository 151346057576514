/* Nidaba Official Font - Neue Machina - These is the stylesheet to include Neue Machina Font to web puclications */
@import url("https://p.typekit.net/p.css?s=1&k=ndk5wdt&ht=tk&f=46215.46216.46217.46218.46219.46220.46203.46204.46205.46206.46207.46208.46209.46210.46211.46212.46213.46214&a=1551293&app=typekit&e=css");

@font-face {
    font-family: "Neue Machina";
    src: url("../fonts/NeueMachina-Ultralight.woff2") format("woff2"),
        url("../fonts/NeueMachina-Ultralight.woff") format("woff");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Machina";
    src: url("../fonts/NeueMachina-Light.woff2") format("woff2"), url("../fonts/NeueMachina-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Machina";
    src: url("../fonts/NeueMachina-Regular.woff2") format("woff2"),
        url("../fonts/NeueMachina-Regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Machina";
    src: url("../fonts/NeueMachina-Ultrabold.woff2") format("woff2"),
        url("../fonts/NeueMachina-Ultrabold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Swear Display";
    src: 
        url("https://use.typekit.net/af/89c043/00000000000000007735e255/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
        url("https://use.typekit.net/af/89c043/00000000000000007735e255/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
        url("https://use.typekit.net/af/89c043/00000000000000007735e255/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: normal;
    font-weight: 400;
    font-stretch: normal;
}

@font-face {
    font-family: "Swear Display";
    src: 
        url("https://use.typekit.net/af/2f259d/00000000000000007735e160/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"),
        url("https://use.typekit.net/af/2f259d/00000000000000007735e160/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"),
        url("https://use.typekit.net/af/2f259d/00000000000000007735e160/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
    font-display: swap;
    font-style: italic;
    font-weight: 400;
    font-stretch: normal;
}

// @font-face {
//     font-family: "Swear Display";
//     src: 
//         url("https://use.typekit.net/af/e118d7/00000000000000007735e16f/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff2"),
//         url("https://use.typekit.net/af/e118d7/00000000000000007735e16f/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("woff"),
//         url("https://use.typekit.net/af/e118d7/00000000000000007735e16f/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n6&v=3") format("opentype");
//     font-display: swap;
//     font-style: italic;
//     font-weight: 600;
//     font-stretch: normal;
// }
