// COLORS

// 👉 Change the variable's values or add new color variables.

$sand:     #ECE2DC;
$white:    $sand;

$gray-100: #F7F7F7;
$gray-200: #DEDCDC;
$gray-300: #C5C0C2;
$gray-400: #ACA5A7;
$gray-500: #93898C;
$gray-600: #7A6E72;
$gray-700: #615257;
$gray-800: #48373C;
$gray-900: #292325;

$black:    #160007;

$blue:    #007BFF;
$indigo:  #6610F2;
$purple:  #6F42C1;
$pink:    #E83E8C;
$red:     #D7282F;
$orange:  #FD7E14;
$yellow:  #FFC107;
$gold:    #DFC566;
$green:   #28A745;
$teal:    #20C997;
$cyan:    #17A2B8;

$primary:       $red;
$secondary:     $gray-600;
$success:       $white;
$info:          $cyan;
$warning:       $gold;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;

body.designsystem{
    [class*="color-"]{
        display: inline-block;
        height: 40px;
        width: 40px;
    }
    
    .color- {
        &primary{ background-color: $primary }
        &black{ background-color: $black }
        &white{ background-color: $white }
        &gray-100{ background-color: $gray-100 }
        &gray-200{ background-color: $gray-200 }
        &gray-300{ background-color: $gray-300 }
        &gray-400{ background-color: $gray-400 }
        &gray-500{ background-color: $gray-500 }
        &gray-600{ background-color: $gray-600 }
        &gray-700{ background-color: $gray-700 }
        &gray-800{ background-color: $gray-800 }
        &gray-900{ background-color: $gray-900 }
        &warning{ background-color: $warning }
        &error{ background-color: $danger }
        &success{ background-color: $success }
    }
}