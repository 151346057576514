// TYPOGRAPHY

// 👉 Add the import of your project's webfonts (ex. Google Fonts, Iconfont, etc..)

// Fontface
// Example: Noto Sans (Google Fonts: https://fonts.google.com/specimen/Noto+Sans)
// @import url('https://fonts.googleapis.com/css?family=Noto+Sans:400,400i,700,700i&display=swap');


// ✅ If you want you could create some variables fort easy font changing
// Paste the node_modules/bootstrap/scss/_variables.scss in there and change the values you want

// 👇 Something like this one below
$font-heading: 'Swear Display';
$font-body: 'Neue Machina',-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";


* {
    /* Better Font Rendering */
    // text-rendering: optimizeLegibility; ⚠️ Optional - That Optimize Legibility (slower rendering)
    // text-rendering: optimizeSpeed; ⚠️ Optional - That Optimize Speed (faster rendering)
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

body {
    font-family: $font-body, sans-serif;
}

h1,
.h1 {
    font: {
        family: $font-heading, serif;
        size: clamp(3.2rem, 6vw, 6.1rem);
        weight: 400;
    }
    white-space: pre-line;
}

h2,
.h2 {
    font: {
        family: $font-heading, serif;
        size: clamp(2.8rem, 5vw, 4.9rem);
        weight: 400;
    }
    white-space: pre-line;
}


h3,
.h3 {
    font: {
        family: $font-heading, serif;
        size: clamp(2rem, 4vw, 3.9rem);
        weight: 400;
    }
    white-space: pre-line;
}

h4,
.h4 {
    font: {
        family: $font-heading, serif;
        size: clamp(1.8rem, 3vw, 3.1rem);
        weight: 400;
    }
    white-space: pre-line;
}

h5,
.h5 {
    font: {
        family: $font-heading, serif;
        size: clamp(1.6rem, 2vw, 2.5rem);
        weight: 400;
    }
    white-space: pre-line;
}

h6,
.h6{
    font: {
        family: $font-heading, serif;
        size: clamp(1.4rem, 1vw, 2rem);
        weight: 400;
    }
    white-space: pre-line;
}

p {
    font-size: clamp(1.4rem, 1em, 1.6rem);
    line-height: 1.2;

    a{
        text-decoration: underline;

        &:hover{
            text-decoration: none;
        }
    }
}

.title{
    &--small{
        display: block;
        font: {
            family: $font-heading, serif;
            size: clamp(3.2rem, 10vw, 7.2rem);
        }
    }

    &--big{
        display: block;
        font: {
            family: $font-heading, serif;
            size: clamp(3.4rem, 12.5vw, 9.4rem);
        }
    }

    &--jumbo{
        display: block;
        font: {
            family: $font-heading, serif;
            size: clamp(4rem, 18vw, 16rem);
        }
    }
}

.tagline{
    display: block;
    font: {
        family: $font-body;
        size: 1.6rem;
        weight: 500;
    }
    letter-spacing: .05ch;
    text-transform: uppercase;
}

.caption{
    display: block;
    color: $gray-500;
    font: {
        family: $font-body;
        size: clamp(1.2rem, 1vw, 1.4rem);
        weight: 500;
    }
    letter-spacing: .05ch;
    text-transform: uppercase;
}