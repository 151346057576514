// MIXIN: Buttons
@mixin btn-default($color: $white, $direction: 'right'){
    display: inline-flex;
    align-items: center;
    position: relative;
    padding: {
        top: #{calc(.5em + 4px)};
        // right: #{calc(1em + .5ch)};
        bottom: .5em;
        left: 0;
    }
    color: $color;
    // color: transparent;
    font: {
        family: $font-body;
        size: clamp(1.4rem, 1em, 1.8rem);
        weight: 500;
    }
    line-height: 1.2;
    text: {
        align: center;
        decoration: none;
        // shadow: 
        //     0 0 $color,
        //     0 10em $color;
    }
    background-color: transparent;
    border: {
        radius: 0;
        style: none;
        width: 0;
        color: transparent;
    }
    will-change: padding, text-shadow, background-position;
    transition: padding .6s $easeInOutQuint, text-shadow .6s $easeInOutQuint, background-position .6s $easeInOutQuint;
    user-select: none;
    overflow: hidden;

    &:before{
        content: '';
        display: block;
        position: relative;
        color: transparent;
        margin: {
            top: #{calc(-.5em - 4px)};
            right: .75ch;
            bottom: -.5em;
        }
        height: 2em;
        width: 2em;
        background: {
            color: transparent;
            // image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.99996L8.30502 16L7.53552 15.2L13.9099 8.57291L0.00587324 8.65093L0 7.51957L13.9236 7.44144L7.53537 0.800004L8.30487 0L16 7.99996Z' fill='#{rgba($color, 0.99)}'/%3E%3Cpath d='M80 7.99996L72.305 16L71.5355 15.2L77.9099 8.57291L64.0059 8.65093L64 7.51957L77.9236 7.44144L71.5354 0.800004L72.3049 0L80 7.99996Z' fill='#{rgba($color, 0.99)}'/%3E%3C/svg%3E%0A");
            // size: 200%;
            repeat: no-repeat;
            // position: 130% 50%;
        }
        border: {
            width: 1px;
            style: solid;
            color: $color;
            radius: 50%;
        }
        will-change: border, background;
        // transition: none;
        transition: border .6s $easeInOutQuint, background .6s $easeInOutQuint;


        @if $direction == "top" {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='80' viewBox='0 0 16 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99996 64L16 71.695L15.2 72.4645L8.57291 66.0901L8.65093 79.9941L7.51957 80L7.44144 66.0764L0.800004 72.4646L0 71.6951L7.99996 64Z' fill='#{rgba($color,.99)}'/%3E%3Cpath d='M7.99996 0L16 7.69498L15.2 8.46448L8.57291 2.0901L8.65093 15.9941L7.51957 16L7.44144 2.07644L0.800004 8.46463L0 7.69513L7.99996 0Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A");
            background-position: 50% -30%;
            background-size: 40%;
        } @else if $direction == "right" {
            background-image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.99996L8.30502 16L7.53552 15.2L13.9099 8.57291L0.00587324 8.65093L0 7.51957L13.9236 7.44144L7.53537 0.800004L8.30487 0L16 7.99996Z' fill='#{rgba($color,.99)}'/%3E%3Cpath d='M80 7.99996L72.305 16L71.5355 15.2L77.9099 8.57291L64.0059 8.65093L64 7.51957L77.9236 7.44144L71.5354 0.800004L72.3049 0L80 7.99996Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A"); 
            background-position: 130% 50%;
            background-size: 200%;
        } @else if $direction == "bottom" {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='80' viewBox='0 0 16 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00004 80L0 72.305L0.800004 71.5355L7.42709 77.9099L7.34907 64.0059L8.48043 64L8.55856 77.9236L15.2 71.5354L16 72.3049L8.00004 80Z' fill='#{rgba($color,.99)}'/%3E%3Cpath d='M8.00004 16L0 8.30502L0.800004 7.53552L7.42709 13.9099L7.34907 0.00587324L8.48043 0L8.55856 13.9236L15.2 7.53537L16 8.30487L8.00004 16Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A");
            background-position: 50% 130%;
            background-size: 40%;
        } @else if $direction == "left" {
            background-image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 8.00004L7.69498 0L8.46448 0.800003L2.0901 7.42709L15.9941 7.34907L16 8.48043L2.07644 8.55856L8.46463 15.2L7.69513 16L0 8.00004Z' fill='#{rgba($color,.99)}'/%3E%3Cpath d='M64 8.00004L71.695 0L72.4645 0.800003L66.0901 7.42709L79.9941 7.34907L80 8.48043L66.0764 8.55856L72.4646 15.2L71.6951 16L64 8.00004Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A");
            background-position: -30% 50%;
            background-size: 200%;
        }
    }
    
    &.active,
    &:hover,
    &:focus{
        // text-shadow: 
        //     0 -10em $color,    
        //     0 0 $color;

        // animation: underlinesnake-icon 2s infinite;
        // background: {
        //     image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='#{rgba($primary,.99)}' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
        //     size: 1em 4px;
        //     repeat: no-repeat;
        // }

        &:before{
            background-color: $primary;
            border-color: $primary;

            @if $direction == "top" {
                background-position: 50% 130%;
            } @else if $direction == "right" {
                background-position: -30% 50%;
            } @else if $direction == "bottom" {
                background-position: 50% -30%;
            } @else if $direction == "left" {
                background-position: 130% 50%;
            }
        }

        transition: border .4s $easeInOutQuint, background .4s $easeInOutQuint;
    }

    &[disabled]{
        color: $gray-500;
        transition: none;
        cursor: not-allowed;
        
        &:before{
            background: {
                image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.99996L8.30502 16L7.53552 15.2L13.9099 8.57291L0.00587324 8.65093L0 7.51957L13.9236 7.44144L7.53537 0.800004L8.30487 0L16 7.99996Z' fill='#{rgba($gray-500, 0.99)}'/%3E%3Cpath d='M80 7.99996L72.305 16L71.5355 15.2L77.9099 8.57291L64.0059 8.65093L64 7.51957L77.9236 7.44144L71.5354 0.800004L72.3049 0L80 7.99996Z' fill='#{rgba($gray-500, 0.99)}'/%3E%3C/svg%3E%0A");
            }
            border-color: $gray-500;
        }

        &:hover{
            text-shadow: none;
        }
    }

    //
    // Variables::Dark (LightMode/Inverted)
    //
    &.dark{
        color: invert($color);

        // text-shadow: 
        //     0 0 invert($color),
        //     0 10em invert($color);

        &:before{
            background-image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.99996L8.30502 16L7.53552 15.2L13.9099 8.57291L0.00587324 8.65093L0 7.51957L13.9236 7.44144L7.53537 0.800004L8.30487 0L16 7.99996Z' fill='#{rgba($color, 0.99)}'/%3E%3Cpath d='M80 7.99996L72.305 16L71.5355 15.2L77.9099 8.57291L64.0059 8.65093L64 7.51957L77.9236 7.44144L71.5354 0.800004L72.3049 0L80 7.99996Z' fill='#{rgba(invert($color), 0.99)}'/%3E%3C/svg%3E%0A");
            border-color: invert($color);
        }

        &:hover{
            // text-shadow: 
            //     0 -10em invert($color),    
            //     0 0 invert($color);

            // animation: underlinesnake-icon 2s infinite;
            // background: {
            //     image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='#{rgba($primary,.99)}' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
            //     position: 6% 90%;
            //     size: 1em 4px;
            //     repeat: no-repeat;
            // }

            &:before{
                border-color: $primary;
            }
        }
    }

    //
    // Variables::No Icon
    //
    &.no--icon{
        padding: {
            top: 0;
            bottom: 0;
        }

        &:before{
            content: none;
        }

        &:hover{
            background: {
                image: url("data:image/svg+xml;charset=utf8,%3Csvg id='squiggle-link' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' xmlns:ev='http://www.w3.org/2001/xml-events' viewBox='0 0 20 4'%3E%3Cstyle type='text/css'%3E.squiggle{animation:shift .3s linear infinite;}@keyframes shift {from {transform:translateX(0);}to {transform:translateX(-20px);}}%3C/style%3E%3Cpath fill='none' stroke='#{rgba($primary,.99)}' stroke-width='2' class='squiggle' d='M0,3.5 c 5,0,5,-3,10,-3 s 5,3,10,3 c 5,0,5,-3,10,-3 s 5,3,10,3'/%3E%3C/svg%3E");
                position: 0 100%;
                size: 10px 2px;
                repeat: repeat-x;
            }
        }
    }

    //
    // Variables::Info
    //
    &[data-info]{
        &:after{
            content: attr(data-info);
            display: block;
            position: relative;
            margin-left: .75ch;
            padding-left: #{calc(1em + .75ch)};
            font: {
                family: $font-heading;
                size: 1.05em;
            }
            background: {
                image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='#{rgba($primary, 0.99)}' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.48485 0.147463V0H7.59596V0.147463C7.59596 4.2765 4.28283 7.59447 0.242424 7.59447H0V8.40553H0.161616C4.20202 8.40553 7.51515 11.7235 7.51515 15.8525V16H8.48485V15.8525C8.48485 11.7235 11.798 8.40553 15.8384 8.40553H16V7.59447H15.8384C11.798 7.59447 8.48485 4.2765 8.48485 0.147463Z' /%3E%3C/svg%3E");
                repeat: no-repeat;
                size: 1em;
                position: left #{calc(50% - 0px)};
            }
            transform: translateY(-2px)
        }
    }
}


@mixin btn-primary($color, $background){
    @include btn-default;
    color: $color;
    background-color: $background;
    will-change: color, background-color, border-color;
    transition: color .5s ease, background-color .5s ease, border-color .5s ease;
    user-select: none;

    &:hover,
    &:focus,
    &:active{
        color: $background;
        border-color: $background;
        background-color: $color;
        transition: color .5s ease, background-color .5s ease, border-color .5s ease;
    }
}

@mixin btn-underline($color: $primary){
    font-weight: 500;
    text-decoration: underline;
    border: none;
    background-color: transparent;
    user-select: none;

    &:hover{
        color: $color;
    }
}

@mixin btn-backlink($color: $primary){
    @include btn-underline($color);
    user-select: none;

    &:before{
        content: '';
        display: inline-block;
        height: 1.15em;
        width: 1.15em;
        vertical-align: -.25em;
        background: {
            image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-arrow-left-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'/%3E%3C/svg%3E");
            size: contain;
            position: center;
            repeat: no-repeat;
        }
    }

    &:hover{
        &:before{
            background: {
                image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{rgba($color,.99)}' class='bi bi-arrow-left-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'/%3E%3C/svg%3E");
            }
        }
    }
}

@mixin btn-nextlink($color: $primary){
    @include btn-underline($color);
    user-select: none;

    &:after{
        content: '';
        display: inline-block;
        height: 1.15em;
        width: 1.15em;
        vertical-align: -.25em;
        background: {
            image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-arrow-left-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'/%3E%3C/svg%3E");
            size: contain;
            position: center;
            repeat: no-repeat;
        }
        transform: scaleX(-1);
    }

    &:hover{
        &:after{
            background: {
                image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#{rgba($color,.99)}' class='bi bi-arrow-left-short' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' d='M12 8a.5.5 0 0 1-.5.5H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5a.5.5 0 0 1 .5.5z'/%3E%3C/svg%3E");
            }
        }
    }
}

@mixin btn-icon($color: $white, $background: $primary, $icon: none){
    @include btn-default($color);
    padding: 0;
    height: 40px;
    width: 40px;

    &:before{
        content: '';
        display: inline-block;
        height: 1.15em;
        width: 1.15em;
        vertical-align: -.25em;
        background: {
            image: url("#{$icon}");
            size: contain;
            position: center;
            repeat: no-repeat;
        }
        pointer-events: none;
    }
}

@mixin btn-arrow($direction: "right", $color: $white){
    display: block;
    position: relative;
    color: transparent;
    height: 2em;
    width: 2em;
    font-size: clamp(1.4rem, 1em, 1.8rem);
    background: {
        color: transparent;
        image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.99996L8.30502 16L7.53552 15.2L13.9099 8.57291L0.00587324 8.65093L0 7.51957L13.9236 7.44144L7.53537 0.800004L8.30487 0L16 7.99996Z' fill='#{rgba($color, 0.99)}'/%3E%3Cpath d='M80 7.99996L72.305 16L71.5355 15.2L77.9099 8.57291L64.0059 8.65093L64 7.51957L77.9236 7.44144L71.5354 0.800004L72.3049 0L80 7.99996Z' fill='#{rgba($color, 0.99)}'/%3E%3C/svg%3E%0A");
        size: 200%;
        repeat: no-repeat;
        position: 130% 50%;
    }
    border: {
        width: 1px;
        style: solid;
        color: $color;
        radius: 50%;
    }
    will-change: border, background;
    transition: border .4s $easeInOutQuint, background .4s $easeInOutQuint;
    text-shadow: none;
    overflow: hidden;

    @if $direction == "top" {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='80' viewBox='0 0 16 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99996 64L16 71.695L15.2 72.4645L8.57291 66.0901L8.65093 79.9941L7.51957 80L7.44144 66.0764L0.800004 72.4646L0 71.6951L7.99996 64Z' fill='#{rgba($color,.99)}'/%3E%3Cpath d='M7.99996 0L16 7.69498L15.2 8.46448L8.57291 2.0901L8.65093 15.9941L7.51957 16L7.44144 2.07644L0.800004 8.46463L0 7.69513L7.99996 0Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A");
        background-position: 50% -30%;
        background-size: 40%;
    } @else if $direction == "right" {
        background-image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.99996L8.30502 16L7.53552 15.2L13.9099 8.57291L0.00587324 8.65093L0 7.51957L13.9236 7.44144L7.53537 0.800004L8.30487 0L16 7.99996Z' fill='#{rgba($color,.99)}'/%3E%3Cpath d='M80 7.99996L72.305 16L71.5355 15.2L77.9099 8.57291L64.0059 8.65093L64 7.51957L77.9236 7.44144L71.5354 0.800004L72.3049 0L80 7.99996Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A"); 
        background-position: 130% 50%;
    } @else if $direction == "bottom" {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='80' viewBox='0 0 16 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00004 80L0 72.305L0.800004 71.5355L7.42709 77.9099L7.34907 64.0059L8.48043 64L8.55856 77.9236L15.2 71.5354L16 72.3049L8.00004 80Z' fill='#{rgba($color,.99)}'/%3E%3Cpath d='M8.00004 16L0 8.30502L0.800004 7.53552L7.42709 13.9099L7.34907 0.00587324L8.48043 0L8.55856 13.9236L15.2 7.53537L16 8.30487L8.00004 16Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A");
        background-position: 50% 130%;
        background-size: 40%;
    } @else if $direction == "left" {
        background-image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 8.00004L7.69498 0L8.46448 0.800003L2.0901 7.42709L15.9941 7.34907L16 8.48043L2.07644 8.55856L8.46463 15.2L7.69513 16L0 8.00004Z' fill='#{rgba($color,.99)}'/%3E%3Cpath d='M64 8.00004L71.695 0L72.4645 0.800003L66.0901 7.42709L79.9941 7.34907L80 8.48043L66.0764 8.55856L72.4646 15.2L71.6951 16L64 8.00004Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A");
        background-position: -30% 50%;
    }

    &.active,
    &:hover,
    &:focus{
        background: {
            color: $primary;
            position: -30% 50%;
        }
        border-color: $primary;

        @if $direction == "top" {
            background-position: 50% 130%;
            background-size: 40%;
        } @else if $direction == "right" {
            background-position: -30% 50%;
        } @else if $direction == "bottom" {
            background-position: 50% -30%;
            background-size: 40%;
        } @else if $direction == "left" {
            background-position: 130% 50%;
        }

    }

    &[disabled]{
        cursor: not-allowed;

        @if $direction == "top" {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='80' viewBox='0 0 16 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99996 64L16 71.695L15.2 72.4645L8.57291 66.0901L8.65093 79.9941L7.51957 80L7.44144 66.0764L0.800004 72.4646L0 71.6951L7.99996 64Z' fill='#{rgba($gray-500,.99)}'/%3E%3Cpath d='M7.99996 0L16 7.69498L15.2 8.46448L8.57291 2.0901L8.65093 15.9941L7.51957 16L7.44144 2.07644L0.800004 8.46463L0 7.69513L7.99996 0Z' fill='#{rgba($gray-500,.99)}'/%3E%3C/svg%3E%0A");
            background-position: 50% -30%;
            background-size: 40%;
        } @else if $direction == "right" {
            background-image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.99996L8.30502 16L7.53552 15.2L13.9099 8.57291L0.00587324 8.65093L0 7.51957L13.9236 7.44144L7.53537 0.800004L8.30487 0L16 7.99996Z' fill='#{rgba($gray-500,.99)}'/%3E%3Cpath d='M80 7.99996L72.305 16L71.5355 15.2L77.9099 8.57291L64.0059 8.65093L64 7.51957L77.9236 7.44144L71.5354 0.800004L72.3049 0L80 7.99996Z' fill='#{rgba($gray-500,.99)}'/%3E%3C/svg%3E%0A"); 
            background-position: 130% 50%;
        } @else if $direction == "bottom" {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='80' viewBox='0 0 16 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00004 80L0 72.305L0.800004 71.5355L7.42709 77.9099L7.34907 64.0059L8.48043 64L8.55856 77.9236L15.2 71.5354L16 72.3049L8.00004 80Z' fill='#{rgba($gray-500,.99)}'/%3E%3Cpath d='M8.00004 16L0 8.30502L0.800004 7.53552L7.42709 13.9099L7.34907 0.00587324L8.48043 0L8.55856 13.9236L15.2 7.53537L16 8.30487L8.00004 16Z' fill='#{rgba($gray-500,.99)}'/%3E%3C/svg%3E%0A");
            background-position: 50% 130%;
            background-size: 40%;
        } @else if $direction == "left" {
            background-image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 8.00004L7.69498 0L8.46448 0.800003L2.0901 7.42709L15.9941 7.34907L16 8.48043L2.07644 8.55856L8.46463 15.2L7.69513 16L0 8.00004Z' fill='#{rgba($gray-500,.99)}'/%3E%3Cpath d='M64 8.00004L71.695 0L72.4645 0.800003L66.0901 7.42709L79.9941 7.34907L80 8.48043L66.0764 8.55856L72.4646 15.2L71.6951 16L64 8.00004Z' fill='#{rgba($gray-500,.99)}'/%3E%3C/svg%3E%0A");
            background-position: -30% 50%;
        }
        border-color: $gray-500;
    }


    //
    // Variables::Dark (LightMode/Inverted)
    //
    &.dark{
        background-image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.99996L8.30502 16L7.53552 15.2L13.9099 8.57291L0.00587324 8.65093L0 7.51957L13.9236 7.44144L7.53537 0.800004L8.30487 0L16 7.99996Z' fill='#{rgba($color, 0.99)}'/%3E%3Cpath d='M80 7.99996L72.305 16L71.5355 15.2L77.9099 8.57291L64.0059 8.65093L64 7.51957L77.9236 7.44144L71.5354 0.800004L72.3049 0L80 7.99996Z' fill='#{rgba(invert($color), 0.99)}'/%3E%3C/svg%3E%0A");
        border: 1px solid invert($color);
        
        @if $direction == "top" {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='80' viewBox='0 0 16 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M7.99996 64L16 71.695L15.2 72.4645L8.57291 66.0901L8.65093 79.9941L7.51957 80L7.44144 66.0764L0.800004 72.4646L0 71.6951L7.99996 64Z' fill='#{rgba(invert($color),.99)}'/%3E%3Cpath d='M7.99996 0L16 7.69498L15.2 8.46448L8.57291 2.0901L8.65093 15.9941L7.51957 16L7.44144 2.07644L0.800004 8.46463L0 7.69513L7.99996 0Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A");
        } @else if $direction == "right" {
            background-image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16 7.99996L8.30502 16L7.53552 15.2L13.9099 8.57291L0.00587324 8.65093L0 7.51957L13.9236 7.44144L7.53537 0.800004L8.30487 0L16 7.99996Z' fill='#{rgba(invert($color),.99)}'/%3E%3Cpath d='M80 7.99996L72.305 16L71.5355 15.2L77.9099 8.57291L64.0059 8.65093L64 7.51957L77.9236 7.44144L71.5354 0.800004L72.3049 0L80 7.99996Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A"); 
        } @else if $direction == "bottom" {
            background-image: url("data:image/svg+xml,%3Csvg width='16' height='80' viewBox='0 0 16 80' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.00004 80L0 72.305L0.800004 71.5355L7.42709 77.9099L7.34907 64.0059L8.48043 64L8.55856 77.9236L15.2 71.5354L16 72.3049L8.00004 80Z' fill='#{rgba(invert($color),.99)}'/%3E%3Cpath d='M8.00004 16L0 8.30502L0.800004 7.53552L7.42709 13.9099L7.34907 0.00587324L8.48043 0L8.55856 13.9236L15.2 7.53537L16 8.30487L8.00004 16Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A");
        } @else if $direction == "left" {
            background-image: url("data:image/svg+xml,%3Csvg width='80' height='16' viewBox='0 0 80 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 8.00004L7.69498 0L8.46448 0.800003L2.0901 7.42709L15.9941 7.34907L16 8.48043L2.07644 8.55856L8.46463 15.2L7.69513 16L0 8.00004Z' fill='#{rgba(invert($color),.99)}'/%3E%3Cpath d='M64 8.00004L71.695 0L72.4645 0.800003L66.0901 7.42709L79.9941 7.34907L80 8.48043L66.0764 8.55856L72.4646 15.2L71.6951 16L64 8.00004Z' fill='#{rgba($color,.99)}'/%3E%3C/svg%3E%0A");
        }

        &:hover{
            border-color: $primary;
        }
    }
}

@mixin nav-link($color: $black){
    position: relative;
    // color: $color;
    color: transparent;
    font: {
        family: $font-heading;
        size: 1.6rem;
        weight: 500;
    }
    letter-spacing: -.015em;
    text: {
        align: center;
        decoration: none;
        transform: uppercase;
    }
    border: {
        radius: 0;
        style: none;
    }
    text-shadow: 
        0 0 $color,
        0 5em $color;
    transition: text-shadow .4s $easeInOutQuint;
    user-select: none;
    overflow: hidden;

    &:after{
        content: '';
        display: block;
        position: absolute;
        height: 5px;
        width: 100%;
        bottom: 0;
        background-color: $primary;
        will-change: transform;
        transition: transform .25s $easeInOutQuint;
        transform-origin: center bottom;
        transform: scaleY(0);
    }

    &.active,
    &.current-menu-item,
    &:hover{
        text-shadow: 
            0 -5em $color,    
            0 0 $color;

        &:after{
            transform: scaleY(1);
        }
    }

    &[disabled]{
        color: $gray-500;
        transition: none;
        cursor: not-allowed;

        &:hover{
            text-shadow: none;
        }
    }
}