$easeInOutQuint: cubic-bezier(0.83, 0, 0.17, 1);

html.loading{
    [data-animation="title"],
    [data-animation="paragraph"]{
        opacity: 0;
    }
}

[data-animation="title"],
[data-animation="paragraph"]{
    span {
        display: inline-block;
        vertical-align: top;
        // overflow: hidden;
        transform-origin: left bottom;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
        // white-space: nowrap;
        
        // &:not(:last-of-type){
        //  margin-right: .5ch;
        // }
            
        > span {
            // border: 1px solid blue;
            
            // &:not(:first-of-type){
            //     margin-top: -.25em;
            // }
        }
    }
    
    // &.title{
    //     > span {
    //         width: 60%;
            
    //         @for $i from 0 through 3 {
    //             @if $i != 1 {
    //                 &:nth-of-type(#{$i}) {
    //                     // padding-left: #{calc(1em * (#{$i} - 1))};
    //                     margin-top: #{calc(-.25em * (#{$i} - 1))};
    //                 }
    //             }
    //         }
    //     }
    // }
    
    // br ~ span {
    //     margin-top: -.5em;
    // }
}

[data-title-images]{
    .line{
        display: block;
        // overflow: hidden;
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);

        &:first-child{
            display: inline-block;
        }

        span{
            position: relative;
            display: inline-block;
        }

        img{
            display: inline-block;
            aspect-ratio: 7 / 5;
            margin: {
                right: .25ch;
                left: .25ch;
            }
            height: 1em;
            object: {
                fit: cover;
                position: center;
            }
            border-radius: 1em;
            transform: translateY(.15em);
        }
    }
}

@keyframes underlinesnake-icon {
    0%{
        background-position: 120% 90%;
    }
    75%{
        background-size: 10px 2px;
    }
    100%{
        background-position: 6% 90%;
        background-size: 0 2px;
    }
}

@keyframes underlinesnake-noicon {
    0%{
        background-position: 120% 90%;
    }
    100%{
        background-position: -20% 90%;
    }
}

@keyframes marquee {
    // 0% {
    //     transform: translate(-33.3333333%);
    // }
    // 100% {
    //     transform: translate(-66.6666666%);
    // }

    0% {
        transform: translate(-25%);
    }
    100% {
        transform: translate(-50%);
    }
}